import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventTypes } from '../../../global/consts';
import { TRACK_AUTH_EVENT_MUTATIONS, UPSERT_GAME_MUTATION } from '../../../global/gql/mutations';
import { GET_GAME_GENRES, GET_ORG_GAMES_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { Event, GameData, GameOperatingSystem, UserData } from '../../../global/interfaces';
import { COLLECTIONS, GAMES, ORG } from '../../../global/routes';
import { useLazyReneQuery, useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { useProdEffect } from '../../../hooks/useProdEffect';
import FileUpload from '../../file-upload/file-upload';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import { validations } from './validations';

import DropdownSearch from '../../dropdown-search/dropdown-search';
import RadioButtons from '../../radio/radio-buttons';
import './new-game-modal.scss';

type NewGameForm = {
  name: string;
  description: string;
  file?: File | undefined;
  category?: string;
  operatingSystem?: GameOperatingSystem;
  url?: string;
  bundleId?: string;
};

interface Props {
  setCloseModal: () => void;
}

const NewGameModal: React.FC<Props> = ({ setCloseModal }) => {
  const uploadFile = useFileUpload();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<NewGameForm>({
    name: '',
    description: '',
    file: undefined,
    category: '',
    operatingSystem: 'ANDROID',
    url: undefined,
    bundleId: undefined,
  });

  const filteredValidations = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(validations).filter(([k]) => (form.operatingSystem === 'WEB' ? k !== 'bundleId' : true)),
      ),
    [form.operatingSystem],
  );
  const { errors, isFieldInvalid, isFormInvalid } = useValidation(filteredValidations);

  const [search, { data, loading: categoriesLoading }] = useLazyReneQuery<{ GameGenreSearch: string[] }>(
    GET_GAME_GENRES,
  );
  const categories = data?.GameGenreSearch || [];

  const handleSearchChange = (value: string) => search({ variables: { gameGenreSearchTerm: value } });

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const [trackEvent] = useReneMutation(TRACK_AUTH_EVENT_MUTATIONS, {
    onError() {},
  });

  const [addGame] = useReneMutation(UPSERT_GAME_MUTATION, {
    onCompleted(data: { UpsertGame: GameData }) {
      if (form?.file) {
        uploadFile(data.UpsertGame.image.uploadUrl, form.file).finally(() => {
          setLoading(false);
          setCloseModal();

          if (location.pathname === `/${ORG}`) {
            navigate(`${GAMES}/${data.UpsertGame.gameId}/${COLLECTIONS}`);
          }
        });
      }
    },
    refetchQueries: [GET_ORG_GAMES_QUERY],
  });

  useProdEffect(() => {
    if (user?.User.email) {
      trackEvent({
        variables: {
          distinctId: user.User.email,
          eventType: EventTypes.GAME_CREATE_STARTED,
        },
      });
    }
  }, []);

  const setFile = (files: File[]) => {
    setForm((prev) => ({ ...prev, file: files?.[0] }));
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (isFieldInvalid('file', { ...form, file })) return;
  };

  const handleFormChange = (e: Event['Input'] | Event['TextArea'] | Event['Select']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAddingNewGame = () => {
    if (isFormInvalid(form)) return;
    let variables: {
      name: string;
      description: string;
      image: { extension?: string };
      isActive: boolean;
      chainSymbol?: string;
      approvedUserScopes: string;
      category?: string;
      operatingSystem?: GameOperatingSystem;
      url?: string;
      bundleId?: string;
    } = {
      name: form.name.trim(),
      description: form.description.trim(),
      image: { extension: form?.file?.type.split('/')[1] },
      isActive: true,
      approvedUserScopes: 'user_all',
      category: form.category,
      operatingSystem: form.operatingSystem,
      url: form.url,
      bundleId: form.bundleId,
    };
    setLoading(true);
    addGame({ variables });
  };

  return (
    <div className="new-game-modal">
      <div className="new-game-modal__heading">
        <h2>New Game</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <Input
        label="Name"
        name="name"
        placeholder="Enter game name"
        handleInput={handleFormChange}
        value={form.name}
        errorMessage={errors?.name}
      />
      <Textarea
        label="Description"
        name="description"
        value={form.description}
        handleInput={handleFormChange}
        placeholder="Enter game description"
        showCounter
        maxLength={100}
        errorMessage={errors?.description}
      />
      <FileUpload
        label="Image"
        setFiles={setFile}
        description="Upload Game Picture"
        errorMessage={errors?.file}
        handleChange={handleFileChange}
      />
      <DropdownSearch
        label="Category"
        options={categories.map((category) => ({ label: category, value: category })) || []}
        selectedValue={form.category}
        handleSelect={(value) => setForm((prev) => ({ ...prev, category: value }))}
        handleSearchChange={handleSearchChange}
        placeholder="Search for game category..."
        loading={categoriesLoading}
        name="category"
      />
      <RadioButtons
        label="Operating System"
        selectedValue={form.operatingSystem as string}
        setSelectedValue={(value) => setForm((prev) => ({ ...prev, operatingSystem: value as GameOperatingSystem }))}
        options={[
          { label: 'Android', value: 'ANDROID' },
          { label: 'iOS', value: 'IOS' },
          { label: 'Web', value: 'WEB' },
        ]}
      />
      {form.operatingSystem === 'WEB' && (
        <Input
          label="Web domain"
          name="url"
          placeholder="Enter URL"
          handleInput={handleFormChange}
          value={form.url}
          errorMessage={errors?.url}
        />
      )}
      {(form.operatingSystem === 'ANDROID' || form.operatingSystem === 'IOS') && (
        <div className="new-game-modal__mobile-inputs">
          <Input
            label={`${form.operatingSystem === 'ANDROID' ? 'Play Store' : 'App Store'} URL`}
            name="url"
            placeholder={`Enter ${form.operatingSystem === 'ANDROID' ? 'Play Store' : 'App Store'} URL`}
            handleInput={handleFormChange}
            value={form.url}
            errorMessage={errors?.url}
          />
          <Input
            label="Bundle ID"
            name="bundleId"
            placeholder="Enter Bundle ID"
            handleInput={handleFormChange}
            value={form.bundleId}
            errorMessage={errors?.bundleId}
          />
        </div>
      )}
      <div className="new-game-modal__actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleAddingNewGame}>
          {loading ? <Spinner size="sm" /> : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default NewGameModal;
