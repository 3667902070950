import { useParams } from 'react-router-dom';
import { GameStatus } from '../../../global/consts';
import { GET_GAMES_GAMER_QUERY } from '../../../global/gql/queries';
import { GameOperatingSystem, GamesData } from '../../../global/interfaces';
import { useReneQuery } from '../../../hooks';
import Icon from '../../../components/Icon/Icon';
import avatar from '../../../global/images/game-placeholder.png';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';

import web from './images/web.svg';
import apple from './images/apple.svg';
import google from './images/google.svg';

import './gamer-game-page.scss';

const connectBtn: { [K in GameOperatingSystem]: { image: string; textSm: string; textLg: string } } = {
  ANDROID: {
    image: google,
    textSm: 'Get it on',
    textLg: 'Google Play',
  },
  IOS: {
    image: apple,
    textSm: 'Download on the',
    textLg: 'Apple Store',
  },
  WEB: {
    image: web,
    textSm: 'Play it on',
    textLg: 'The Web',
  },
};
const GamerGamePage = () => {
  const params = useParams();
  const { data: reneGames, loading: gameLoading } = useReneQuery<{ Games: GamesData }>(GET_GAMES_GAMER_QUERY, {
    variables: {
      gameId: params.gameId,
    },
  });

  const game = reneGames?.Games.items[0];
  const isGameConnected = game?.gameConnect?.status === GameStatus.CONNECT_CONFIRMED;

  return (
    <div className="gamer-game-page">
      <div className="gamer-game-page__heading">
        <div
          style={{
            backgroundImage: `url(${game?.image?.url})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          className="gamer-game-page__heading_poster"
        />
        <img src={game?.image?.url || avatar} alt="game" />
        <div className="gamer-game-page__heading_info">
          <div className="gamer-game-page__heading_info_title">
            <h2>{game?.name}</h2>
            <div>
              <p className={isGameConnected ? 'connected' : ''}>{isGameConnected ? 'Connected' : 'Connect to earn'}</p>
              <p>{game?.category}</p>
            </div>
          </div>
          {game?.operatingSystem && (
            <div className="gamer-game-page__heading_info_store">
              <img src={connectBtn[game?.operatingSystem as GameOperatingSystem].image} alt="store" />
              <div>
                <p>{connectBtn[game?.operatingSystem as GameOperatingSystem].textSm}</p>
                <p>{connectBtn[game?.operatingSystem as GameOperatingSystem].textLg}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {gameLoading ? (
        <LoadingFallback />
      ) : (
        <div className="gamer-game-page__connect">
          <h3>
            <Icon name="chain" />
            How to connect
          </h3>
          <div>
            <Icon name="soon" size={80} />
            <p>Coming soon</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default GamerGamePage;
